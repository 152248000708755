import { css } from '@emotion/react'
import { createRef, type FC } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useFormContext } from 'react-hook-form'
import * as Yup from 'yup'

const style = {
  recaptcha: css({
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  })
}
const reCAPTCHA_ACTIVE = process.env.NEXT_PUBLIC_RECAPTCHA_ACTIVE === 'TRUE'

export const reCAPTCHASchema = {
  recaptcha_token: reCAPTCHA_ACTIVE
    ? Yup.string().required('reCAPTCHAをチェックしてください')
    : Yup.string()
}
const yupObject = Yup.object(reCAPTCHASchema)
type ReCAPTCHAValue = Yup.InferType<typeof yupObject>
export const ReCAPTCHAForm: FC = () => {
  const { setValue } = useFormContext<ReCAPTCHAValue>()
  const recaptchaRef = createRef<ReCAPTCHA>()
  const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string
  if (!reCAPTCHA_ACTIVE) return null
  return (
    <div css={style.recaptcha}>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={siteKey}
        onChange={(value) => value && setValue('recaptcha_token', value)}
        size="normal"
      />
    </div>
  )
}
