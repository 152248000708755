import { FC } from 'react'
import { TextInput, TextInputProps } from '@/components/Input/TextInput'
import { FieldValues } from 'react-hook-form'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'

const styles = {
  label: css({
    fontWeight: 'bold'
  })
}

interface TextInputWithLabelProps<T extends FieldValues>
  extends Omit<TextInputProps<T>, 'title' | 'id'> {
  title: string
  id: string
}

export const TextInputWithLabel = <T extends FieldValues = never>(
  props: TextInputWithLabelProps<T>
): ReturnType<FC> => {
  return (
    <div>
      <label css={[typography.textS, styles.label]} htmlFor={props.id}>
        {props.title}
      </label>
      <TextInput {...props} />
    </div>
  )
}
