import { useEffect, useMemo, FC } from 'react'
import { useKana } from 'react-use-kana'
import { useFormContext } from 'react-hook-form'
import { TextInputWithLabel } from '@/components/TextInputWithLabel'
import { ErrorMessage } from '@/components/Input/ErrorMessage'
import { RequiredLabel } from '@/components/RequiredLabel'
import * as Yup from 'yup'
import { FormItemFrame } from '@/components/FormItemFrame'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { EventTracker } from '@lifedot/tracking'

const styles = {
  layout: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '4px 12px'
  })
}

export const userNameSchema = {
  last_name: Yup.string()
    .trim()
    .required('姓を入力してください')
    .max(30, '姓は30文字以内で入力してください'),
  last_kana: Yup.string()
    .trim()
    .required('せい(ふりがな)を入力してください')
    .max(30, 'せい(ふりがな)は30文字以内で入力してください'),
  first_name: Yup.string()
    .trim()
    .required('名を入力してください')
    .max(30, '名は30文字以内で入力してください'),
  first_kana: Yup.string()
    .trim()
    .required('めい(ふりがな)を入力してください')
    .max(30, 'めい(ふりがな)30文字以内で入力してください')
}

const yupObject = Yup.object(userNameSchema)
type UserNameFormValues = Yup.InferType<typeof yupObject>

export const UserName: FC = () => {
  const { kana: lastKana, setKanaSource: setLastKanaSource } = useKana()
  const { kana: firstKana, setKanaSource: setFirstKanaSource } = useKana()

  const { setValue, watch } = useFormContext<UserNameFormValues>()
  const [firstKanaValue, firstNameValue, lastKanaValue, lastNameValue] = watch(
    ['first_kana', 'first_name', 'last_kana', 'last_name'],
    {
      first_name: '',
      last_name: ''
    }
  )
  const firstKanaValid = useMemo(
    () => userNameSchema.first_kana.isValidSync(firstKanaValue),
    [firstKanaValue]
  )
  const firstNameValid = useMemo(
    () => userNameSchema.first_name.isValidSync(firstNameValue),
    [firstNameValue]
  )
  const lastKanaValid = useMemo(
    () => userNameSchema.last_kana.isValidSync(lastKanaValue),
    [lastKanaValue]
  )
  const lastNameValid = useMemo(
    () => userNameSchema.last_name.isValidSync(lastNameValue),
    [lastNameValue]
  )

  useEffect(() => {
    setValue('first_kana', firstKana)
  }, [setValue, firstKana])
  useEffect(() => {
    setValue('last_kana', lastKana)
  }, [setValue, lastKana])

  useEffect(() => {
    setFirstKanaSource(firstNameValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstNameValue])
  useEffect(() => {
    setLastKanaSource(lastNameValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastNameValue])

  return (
    <FormItemFrame
      title={
        <>
          <p css={typography.textL}>
            <b>お名前</b>
          </p>
          <RequiredLabel
            allInputted={
              firstKanaValid && firstNameValid && lastKanaValid && lastNameValid
            }
          />
        </>
      }
    >
      <div css={styles.layout}>
        <div>
          <EventTracker
            label="lastName"
            action="click"
            elementLabel="last_name"
          >
            <TextInputWithLabel<UserNameFormValues>
              name="last_name"
              title="姓"
              placeholder="例：田中"
              id="last_name"
            />
          </EventTracker>
          <ErrorMessage<UserNameFormValues> name="last_name" />
        </div>
        <div>
          <EventTracker
            label="firstName"
            action="click"
            elementLabel="first_name"
          >
            <TextInputWithLabel<UserNameFormValues>
              name="first_name"
              title="名"
              placeholder="例：一郎"
              id="first_name"
            />
          </EventTracker>
          <ErrorMessage<UserNameFormValues> name="first_name" />
        </div>
        <div>
          <EventTracker
            label="lastKana"
            action="click"
            elementLabel="last_kana"
          >
            <TextInputWithLabel<UserNameFormValues>
              name="last_kana"
              title="せい"
              placeholder="例：たなか"
              id="last_kana"
            />
          </EventTracker>
          <ErrorMessage<UserNameFormValues> name="last_kana" />
        </div>
        <div>
          <EventTracker
            label="firstKana"
            action="click"
            elementLabel="first_kana"
          >
            <TextInputWithLabel<UserNameFormValues>
              name="first_kana"
              title="めい"
              placeholder="例：いちろう"
              id="first_kana"
            />
          </EventTracker>
          <ErrorMessage<UserNameFormValues> name="first_kana" />
        </div>
      </div>
    </FormItemFrame>
  )
}
