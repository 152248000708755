import { useMemo, FC } from 'react'
import * as Yup from 'yup'
import { TextInput } from '@/components/Input/TextInput'
import { ErrorMessage } from '@/components/Input/ErrorMessage'
import { RequiredLabel } from '@/components/RequiredLabel'
import { useFormContext } from 'react-hook-form'
import { FormItemFrame } from '@/components/FormItemFrame'
import { typography } from '@lifedot/styles/typography'
import { palette } from '@lifedot/styles/palette'
import { css } from '@emotion/react'
import { Accordion } from '@lifedot/atoms/Accordion'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { SupplementText } from '@/components/SupplementText'
import { EventTracker } from '@lifedot/tracking'
import { lifedotDirectNumber } from '@lifedot/constants/phoneNumbers'

const styles = {
  accordion: css({
    color: palette.gray6,
    textDecoration: 'underline',
    marginTop: 4,
    display: 'flex',
    alignItems: 'center'
  }),
  list: css({
    li: {
      paddingTop: 4
    }
  })
}

export const userTelSchema = {
  tel: Yup.string()
    .required('電話番号を入力してください')
    .transform((value) =>
      `${value}`
        .replace(/-|ー|\s/g, '')
        .replace(/[０-９]/g, (s) =>
          String.fromCharCode(s.charCodeAt(0) - 65248)
        )
    )
    .matches(
      /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/,
      '正しい電話番号を入力し直してください'
    )
}

const yupObject = Yup.object(userTelSchema)
type UserTelFormValues = Yup.InferType<typeof yupObject>

export const UserTel: FC = () => {
  const { watch } = useFormContext<UserTelFormValues>()
  const tel = watch('tel')
  const allInputted = useMemo(() => userTelSchema.tel.isValidSync(tel), [tel])
  return (
    <FormItemFrame
      title={
        <>
          <p css={typography.textL}>
            <b>電話番号</b>
          </p>
          <RequiredLabel allInputted={allInputted} />
        </>
      }
    >
      <EventTracker label="tel" action="click" elementLabel="tel">
        <TextInput<UserTelFormValues>
          name="tel"
          placeholder="例：03333333333"
          type="tel"
        />
      </EventTracker>
      <ErrorMessage<UserTelFormValues> name="tel" />
      <Accordion
        title={
          <p css={[typography.textS, styles.accordion]}>
            電話番号をご入力いただく理由
            <KeyboardArrowDownIcon fontSize="small" />
          </p>
        }
      >
        <ul css={[typography.textS, styles.list]}>
          <li>
            <SupplementText text="資料送付や見学に関わるご確認やご登録いただいた情報に不備があった際に確認のご連絡を差し上げる場合がございます。" />
          </li>
          <li>
            <SupplementText
              text={`無理な営業はいたしません。もしございました場合はライフドット相談窓口（${lifedotDirectNumber}）までご連絡ください。`}
            />
          </li>
        </ul>
      </Accordion>
    </FormItemFrame>
  )
}
