import { FC } from 'react'
import { FieldValues } from 'react-hook-form'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { Select, SelectProps } from '@/components/Input/Select'

const styles = {
  label: css({
    fontWeight: 'bold'
  })
}

interface SelectWithLabelProps<T extends FieldValues> extends SelectProps<T> {
  title: string
}

export const SelectWithLabel = <T extends FieldValues = never>(
  props: SelectWithLabelProps<T>
): ReturnType<FC> => {
  return (
    <div>
      <label css={[typography.textS, styles.label]} htmlFor={props.id}>
        {props.title}
      </label>
      <Select {...props} />
    </div>
  )
}
