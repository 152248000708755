import { useEffect, useMemo, FC } from 'react'
import * as Yup from 'yup'
import LaunchIcon from '@material-ui/icons/Launch'
import { prefectures } from '@lifedot/constants/prefectures'
import { usePostalCodeToAddress } from '@lifedot/hooks/use-postal-code-to-address'
import { typography } from '@lifedot/styles/typography'
import { ErrorMessage } from '@/components/Input/ErrorMessage'
import { useFormContext } from 'react-hook-form'
import { TextInputWithLabel } from '@/components/TextInputWithLabel'
import { RequiredLabel } from '@/components/RequiredLabel'
import { FormItemFrame } from '@/components/FormItemFrame'
import { SelectWithLabel } from '@/components/SelectWithLabel'
import { SupplementText } from '@/components/SupplementText'
import { css } from '@emotion/react'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { EventTracker } from '@lifedot/tracking'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  postalCode: css({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '49% 1fr',
    gap: 12,
    [mq('sp')]: {
      gridTemplateColumns: '48% 1fr'
    }
  }),
  prefecture: css({
    width: '49%',
    [mq('sp')]: {
      width: '48%'
    }
  }),
  anchor: css({
    display: 'flex',
    alignItems: 'center',
    transform: 'translateY(50%)'
  })
}

export const userAddressSchema = {
  postal_code: Yup.string()
    .trim()
    .transform((value) =>
      value
        .replace(/-|ー/g, '')
        .replace(/[０-９]/g, (s: string) =>
          String.fromCharCode(s.charCodeAt(0) - 65248)
        )
    )
    .matches(/(^\d{3}-{0,1}\d{4}$)|^$/, '正しい郵便番号ではありません'),
  address1: Yup.string().required('都道府県を選択してください'),
  address2: Yup.string()
    .trim()
    .required('市区町村名を入力してください')
    .max(50, '市区町村名は50文字以内で入力してください'),
  address3: Yup.string()
    .trim()
    .required('番地・建物名を入力してください')
    .max(50, '番地・建物名は50文字以内で入力してください')
}
const yupObject = Yup.object(userAddressSchema)
type UserAddressFormValues = Yup.InferType<typeof yupObject>
const prefectureValues = Object.values(prefectures).map((value) => ({
  label: value,
  value
}))

export const UserAddress: FC = () => {
  const { watch, setValue } = useFormContext<UserAddressFormValues>()
  const postalCode = watch('postal_code') ?? ''
  const [address1, address2, address3] = watch([
    'address1',
    'address2',
    'address3'
  ])
  const address1Valid = useMemo(
    () => userAddressSchema.address1.isValidSync(address1),
    [address1]
  )
  const address2Valid = useMemo(
    () => userAddressSchema.address2.isValidSync(address2),
    [address2]
  )
  const address3Valid = useMemo(
    () => userAddressSchema.address3.isValidSync(address3),
    [address3]
  )

  const { address } = usePostalCodeToAddress(
    postalCode,
    !!postalCode && userAddressSchema.postal_code.isValidSync(postalCode)
  )

  useEffect(() => {
    if (!address) return

    const { prefecture_name, city_name, town_name } = address
    setValue('address1', prefecture_name)
    setValue('address2', `${city_name}${town_name ?? ''}`)
  }, [address, setValue])

  return (
    <FormItemFrame
      title={
        <>
          <p css={typography.textL}>
            <b>ご住所</b>
          </p>
          <RequiredLabel
            allInputted={address1Valid && address2Valid && address3Valid}
          />
        </>
      }
    >
      <AddMarginWrapper spacing={2}>
        <div css={styles.postalCode}>
          <EventTracker
            label="postalCode"
            action="click"
            elementLabel="postal_code"
          >
            <TextInputWithLabel<UserAddressFormValues>
              name="postal_code"
              title="郵便番号"
              placeholder="例：1234567"
              id="postal_code"
            />
          </EventTracker>
          <ErrorMessage<UserAddressFormValues> name="postal_code" />
          <a
            href="http://www.post.japanpost.jp/zipcode/index.html"
            target="_blank"
            rel="noreferrer"
            css={[typography.textS, styles.anchor]}
          >
            郵便番号を調べる
            <LaunchIcon fontSize="small" />
          </a>
        </div>
        <div css={styles.prefecture}>
          <EventTracker label="address1" action="click" elementLabel="address1">
            <SelectWithLabel<UserAddressFormValues>
              name="address1"
              title="都道府県"
              values={prefectureValues}
              defaultOption="都道府県を選ぶ"
              id="address1"
            />
          </EventTracker>
          <ErrorMessage<UserAddressFormValues> name="address1" />
        </div>
        <div>
          <EventTracker label="address2" action="click" elementLabel="address2">
            <TextInputWithLabel<UserAddressFormValues>
              name="address2"
              title="市区町村"
              placeholder="例：○○市"
              id="address2"
            />
          </EventTracker>
          <ErrorMessage<UserAddressFormValues> name="address2" />
        </div>
        <div>
          <EventTracker label="address3" action="click" elementLabel="address3">
            <TextInputWithLabel<UserAddressFormValues>
              name="address3"
              title="番地・建物名"
              placeholder="例：1-11-1 ○○マンション101"
              id="address3"
            />
          </EventTracker>
          <ErrorMessage<UserAddressFormValues> name="address3" />
        </div>
        <SupplementText text="お問い合わせ内容に基づいた詳細な資料を無料でお送りいたします。" />
      </AddMarginWrapper>
    </FormItemFrame>
  )
}
