import { useEffect, useState } from 'react'
import { prefectures } from '@lifedot/constants/prefectures'

type ResponseAddress = {
  city_name: string
  prefecture_code: string
  prefecture_roma: string
  prefecture_name: string
  town_name: string | null
}

const replaceFullToHalf = (str: string): string => {
  return str.replace(/[！-～]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  })
}

const fetchAddress = async (
  code: string
): Promise<ResponseAddress | null | never> => {
  let newCode = replaceFullToHalf(code)
  if (/^\d{7}$/.test(replaceFullToHalf(code)))
    newCode = `${newCode.slice(0, 3)}-${newCode.slice(3, 7)}`
  if (!/^\d{3}-\d{4}$/.test(newCode))
    throw new Error('正しい郵便番号を入力してください。')
  const res = await fetch(
    `https://www.lifedot.jp/api/postal_codes?postal_code=${newCode}`
  )
  if (!res.ok) return null
  const data: Omit<ResponseAddress, 'prefecture_roma'>[] = await res.json()
  const [address] = data
  if (!address) return null
  return {
    ...address,
    prefecture_roma:
      Object.entries(prefectures).find(
        ([, name]) => name === address.prefecture_name
      )?.[0] ?? ''
  }
}

export const usePostalCodeToAddress = (
  postalCode: string,
  ready = true
): {
  address: ResponseAddress | null
  loading: boolean
  error: null | Error
} => {
  const [address, setAddress] = useState<ResponseAddress | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    let mounted = true
    if (!ready) return
    setLoading(true)
    fetchAddress(postalCode)
      .then((res) => {
        mounted && setAddress(res)
        mounted && setError(null)
      })
      .catch((e) => {
        mounted && setAddress(null)
        mounted && setError(e)
      })
      .finally(() => {
        mounted && setLoading(false)
      })
    return () => {
      mounted = false
    }
  }, [postalCode, ready])

  return { address, loading, error }
}
